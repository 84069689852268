.section-container {
  display: flex;
  flex-direction: column;
  padding: 50px 200px 50px;
  min-height: 100vh;
}

.section-title {
  align-self: center;
  font-size: calc(18pt + 1vw);
}

@media (max-width: 600px) {
  .section-container {
    padding: 100px 20px 50px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .section-container {
    padding: 100px 30px 100px;
  }
}

@media (max-width: 1400px) and (min-width: 800px) {
  .section-container {
    padding: 100px 50px 100px;
  }
}
