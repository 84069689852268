.about-container {
  background-color: var(--background-primary);
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(40%);
    opacity: 0%;
  }
  100% {
    transform: translateY(0);
    visibility: visible;
  }
}

.about-body-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
}

.supertitle {
  color: var(--text-secondary);
  animation-name: slideInFromTop;
  animation-duration: 1.5s;
  animation-delay: 0.5s;
  animation-play-state: initial;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.supertitle-2 {
  color: var(--text-secondary);
  animation-name: slideInFromTop;
  animation-duration: 1.5s;
  animation-delay: 1s;
  animation-play-state: initial;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.name {
  font-size: calc(24px + 3vw);
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 50px;
  animation: 0.5s ease-in-out 0s 1 slideInFromTop;
  width: 100%;
}

.subtitle {
  font-size: calc(18px + 2vw);
  color: var(--text-tertiary);
  margin-bottom: 0;
  margin-top: 0;
  animation: 1.5s ease 0s normal forwards 1 slideInFromTop;
  text-wrap: normal;
}

.location {
  color: var(--text-secondary);
  margin-bottom: 80px;
  animation-name: slideInFromTop;
  animation-duration: 1.5s;
  animation-delay: 1.5s;
  animation-play-state: initial;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.summary {
  padding-bottom: 20px;
  text-align: right;
  display: inline;
  width: 100%;
}
