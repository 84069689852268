* {
  /*brand*/
  --brand-hue: 200;
  --brand-saturation: 100%;
  --brand-lightness: 50%;
  --brand-light: hsl(
    var(--brand-hue) var(--brand-saturation) var(--brand-lightness)
  );
  /*text*/
  --text1-light: hsl(var(--brand-hue) var(--brand-saturation) 10%);
  --text2-light: hsl(var(--brand-hue) 30% 30%);
  --text3-light: hsl(var(--brand-hue) 80% 40%);
  /*surface*/
  --surface1-light: hsl(var(--brand-hue) 30% 95%);
  --surface2-light: hsl(var(--brand-hue) 20% 99%);
  --surface3-light: hsl(var(--brand-hue) 35% 80%);
  --surface4-light: hsl(var(--brand-hue) 20% 85%);
  /*shadow*/
  --surface-shadow-light: var(--brand-hue) 10% calc(var(--brand-lightness) / 5);
  --shadow-strength-light: 0.02;
  margin: 0;
  padding: 0;
}

:root {
  --background-primary: var(--surface1-light);
  --background-secondary: var(--surface2-light);
  --background-tertiary: var(--surface3-light);
  --background-accent: var(--surface4-light);
  --text-primary: var(--text1-light);
  --text-secondary: var(--text3-light);
  --text-tertiary: var(--brand-light);
  --accent: var(--brand-light);
  background-color: var(--background-primary);
  font-size: calc(6pt + 0.6vw);
}

/*@media (prefers-color-scheme: dark) {*/
/*  :root {*/
/*    --background-primary: #000a1e;*/
/*    --background-secondary: #010f2c;*/
/*    --background-tertiary: #011744;*/
/*    --background-accent: #1b3c4e;*/
/*    --text-primary: #c9ddff;*/
/*    --text-secondary: #41d1de;*/
/*    --text-tertiary: #8995a5;*/
/*    --accent: #9dedff;*/
/*  }*/
/*}*/

:root.dark {
  --background-primary: #000a1e;
  --background-secondary: #010f2c;
  --background-tertiary: #011744;
  --background-accent: #1b3c4e;
  --text-primary: #c9ddff;
  --text-secondary: #41d1de;
  --text-tertiary: #8995a5;
  --accent: #3c3f41;
}

.App {
  background-color: var(--background-primary);
  color: var(--text-primary);
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  text-align: left;
  width: 100%;

}

.body-container {
  display: flex;
  flex-grow: 1;
  background-color: var(--background-secondary);
  text-align: left;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.right-column {
}

@media (max-width: 600px) {
  .body-container {
    flex-direction: column;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .body-container {
    flex-direction: column;
  }
}

@media (max-width: 1400px) and (min-width: 800px) {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a {
  position: relative;
  color: var(--text-secondary);
  text-decoration: none;
}

a:before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--text-secondary);
  visibility: hidden;
  transition: all 0.5s cubic-bezier(0.87, -0.08, 0.54, 1.3);
}

a:hover:after {
}

a:hover:before {
  visibility: visible;
  background-color: var(--accent);
  width: 100%;
}

a:hover {
  color: var(--accent);
}

.footer {
  background-color: var(--background-accent);
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 10px;
  color: var(--text-secondary);
  font-size: calc(8px + 0.4vw);
  white-space: pre-wrap;
}
