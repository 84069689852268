.ContactForm {
  padding-bottom: 50px;
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  min-height: 70vh;
}

.contact-form-input-field {
}

.contact-form-text-field {
  display: flex;
  flex-direction: column;
  min-height: 150px;
}

.contact-form-submit {
}

@media (max-width: 800px) {
  Form {
    width: 80%;
  }
}

Form {
  justify-self: center;
  align-self: center;
  /*max-width: 500px;*/
  /*border: 1px solid var(--accent);*/
  border-radius: 10px;
  /*background-color: var(--background-tertiary);*/
  padding: 50px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

Input {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  height: 40px;
  background-color: var(--background-secondary);
  color: var(--accent);
  border: var(--accent) solid;
  border-radius: 5px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  padding-left: 20px;
  padding-top: 0;
  padding-bottom: 0;
}

TextArea {
  width: 100%;
  height: 200px;
  background-color: var(--background-secondary);
  border: var(--accent) solid;
  border-radius: 5px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-left: 20px;
  color: var(--accent);
  padding-bottom: 0;
}

Button {
  width: 150px;
  height: 30px;
  background-color: var(--background-secondary);
  border: var(--accent) solid;
  border-radius: 5px;
  margin-top: 20px;
  color: var(--accent);
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
Label {
  color: var(--accent);
}

@media (max-width: 600px) {
  Form {
    align-items: center;
    align-content: center;
    width: 80%;
    padding: 10px;
  }
  Input {
    width: 100%;
  }
  TextArea {
    width: 100%;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  Form {
    align-items: center;
    align-content: center;
    width: 500px;
    padding: 20px;
  }
  Input {
    width: 450px;
  }
  TextArea {
    width: 450px;
  }
}
