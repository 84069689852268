.Education-container {
  background-color: var(--background-primary);
  /*-webkit-box-shadow: 0px 0px 56px -29px rgba(0,0,0,0.65);*/
  /*-moz-box-shadow: 0px 0px 56px -29px rgba(0,0,0,0.65);*/
  box-shadow: 0px 0px 30px -20px rgba(0, 0, 0, 0.65);
  margin: 10vw;
  border-radius: 50px;
  gap: 100px;
  min-height: 0;
}
