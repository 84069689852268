.Experience-container {
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}

.Experience-sub-container {
  background-color: var(--background-primary);
  display: flex;
  flex-direction: row;
  min-height: 700px;
  justify-content: center;
  justify-items: center;
  align-items: stretch;
  padding-top: 50px;
}

.experience-toggle {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.experience-toggle-item {
  width: 300px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  border-right: var(--surface3-light) solid 5px;
  margin: 5px;
}

.experience-toggle-item-selected {
  width: 320px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  background-color: var(--surface3-light);
  border-right: var(--brand-light) solid 5px;
  margin: 5px;
  color: black;
}

.experience-toggle-item-label {
  text-align: right;
  margin-right: 10px;
  border-radius: 1px;
}

.experience-display {
  display: flex;
  flex-grow: 3;
  height: 100%;
  margin-left: 100px;
  width: 100%;
}

.experience-display-item {
  margin-top: 20px;
}

.experience-display-item-section {
  margin-bottom: 20px;
  margin-top: 20px;
}

.experience-display-item-section-name {
  margin-bottom: 10px;
  margin-top: 10px;
  color: var(--text-secondary);
}

.role {
  color: var(--text-tertiary);
}

.bullet {
  margin-left: 20px;
  margin-bottom: 10px;
}

@media (max-width: 800px) {
  .Experience-container {
    background-color: var(--background-primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    height: 0;
    padding: 20px;
  }

  .Experience-sub-container {
    background-color: var(--background-primary);
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-left: 0;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    width: 100%;
  }

  .experience-toggle {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin-left: 0;
    width: 80%;
    justify-items: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: stretch;
    align-content: stretch;
    background-color: var(--surface2-light);
    border-radius: 20px;
  }

  .experience-toggle-item {
    width: 0;
    display: flex;
    flex-direction: column;
    border-right: var(--surface3-light) solid 5px;
    margin: 5px;
    text-align: center;
    padding: 5px;
    flex-grow: 1;
    border-right: var(--surface3-light) solid 5px;
    border-radius: 20px;
    height: 30px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    background-color: unset;
  }

  .experience-toggle-item-selected {
    width: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: var(--brand-light) solid 5px;
    margin: 5px;
    align-items: center;
    text-align: center;
    padding: 5px;
    flex-grow: 1;
    border-right: var(--brand-light) solid 5px;
    border-radius: 20px;
    height: 30px;
    background-color: unset;
  }

  .experience-toggle-item-label {
    text-align: right;
    margin-right: 10px;
    border-radius: 1px;
  }

  .experience-display {
    display: flex;
    padding-left: 0;
    margin-left: 0;
    width: 80%;
  }

  .experience-display-item {
    margin-top: 20px;
  }

  .experience-display-item-section {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .experience-display-item-section-name {
    margin-bottom: 10px;
    margin-top: 10px;
    color: var(--text-secondary);
  }

  .role {
    color: var(--text-tertiary);
  }

  .bullet {
    margin-left: 20px;
    margin-bottom: 10px;
  }
}
