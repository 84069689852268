.fab-container {
  background-color: var(--brand-light);
  position: fixed;
  bottom: 1.5rem;
  right: 2rem;

  box-shadow: 0 0.13333rem 0.4rem 0 rgba(255, 80, 0, 0.4);

  border-radius: 1.5rem;
  color: white;
  font-weight: 700;
  font-size: 1.5rem;

  width: 3rem;
  height: 3rem;

  line-height: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 100;
}
