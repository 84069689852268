.nav-bar {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-self: flex-start;
  position: sticky;
  top: 0;
  opacity: 1;
  background-color: var(--background-accent);
  color: var(--background-primary);
  padding: 25px;
  z-index: 1;
  box-shadow: 0 0 30px -20px rgba(0, 0, 0, 0.65);
  height: fit-content;
  justify-items: center;
}

.nav-bar-settings {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-bar-links {
  display: flex;
  gap: 25px;
  justify-content: flex-end;
  align-self: flex-start;
  position: sticky;
  top: 0;
  opacity: 1;
  background-color: var(--background-accent);
  color: var(--background-primary);
  z-index: 1;
  align-items: center;
}

@media (max-width: 800px) {
  .nav-bar {
    font-size: calc(6pt + 0.8vw);
  }
}

.highlighted > a {
  color: var(--accent);
  text-decoration: underline;
  text-decoration-thickness: 2px;
  content: '';
  transition: all 0.5s cubic-bezier(0.87, -0.08, 0.54, 1.3);
  /*margin: 10px;*/
}

.cv-link {
  border: var(--brand-light) solid;
  padding: 10px;
}
