.contact-container {
  display: flex;
  justify-content: center;
  background-color: var(--background-accent);
  justify-self: center;
  position: -webkit-sticky;
  top: 70px;
  left: 0;
  flex-grow: 1;
  padding: 20px;
  gap: 25px;
  position: inherit;
}

.contact-link {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-bottom: -10px;
  margin-left: 5px;
}

.contact-link:hover {
  position: sticky;
}

.contact-link:hover .contact-info {
  visibility: visible;
  transition: all 0.5s cubic-bezier(0.87, -0.08, 0.54, 1.3);
}

.contact-logo {
  height: 30px;
  width: 30px;
}

.contact-info {
  max-height: 1px;
  font-size: calc(8pt + 0.5vw);
  position: unset;
  top: 70px;
  align-self: center;
  visibility: visible;
  display: none;
}
