.Skills {
  background-color: var(--background-primary);
  /*-webkit-box-shadow: 0px 0px 56px -29px rgba(0,0,0,0.65);*/
  /*-moz-box-shadow: 0px 0px 56px -29px rgba(0,0,0,0.65);*/
  box-shadow: 0px 0px 30px -20px rgba(0, 0, 0, 0.65);
  margin: 5vw;
  border-radius: 50px;
  gap: 50px;
  min-height: 0;
  padding: 120px 120px 120px;
}

.Skills-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-evenly;
  /*gap: calc(20px + 0.5vw);*/
}

.Skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  min-width: 120px;
  margin: calc(10px + 0.1vw) calc(10px + 0.1vw) 10px;
}

.skills-description {
  text-align: right;
  line-height: 30px;
}

.Skill-icon {
  height: calc(25px + 2vw);
  margin: 10px;
}

.Skill-title {
  width: fit-content;
  text-align: center;
  text-justify: inter-word;
  inline-size: 100px;
  font-size: 16px;
}

.skills-toggle {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-items: center;
  align-items: center;
  flex-wrap: wrap;
  justify-content: stretch;
  align-content: stretch;
  background-color: var(--surface2-light);
  border-radius: 20px;
}

.skills-toggle-item {
  background-color: var(--surface2-light);
  text-align: center;
  padding: 5px;
  flex-grow: 1;
  border-right: var(--surface3-light) solid 5px;
  border-radius: 20px;
  height: 30px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  display: flex;
  color: #1b3c4e;
}

.skills-toggle-item-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
  flex-grow: 1;
  border-right: var(--brand-light) solid 5px;
  border-radius: 20px;
  height: 30px;
  color: var(--brand-light);
}
