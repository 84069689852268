.selector-container {
  margin-left: 10px;
  margin-right: 10px;
  background-color: var(--background-tertiary);
}

.language {
  max-width: 80px;
  padding: 10px;
  color: red;
  min-width: 100px;
  text-align: center;
}

.selector-dropdown-container {
  position: fixed;
  z-index: 10;
  min-width: 100px;
  background-color: var(--background-secondary);
}
