.item-header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item-header-title-container {
  font-size: calc(12pt + 1vw);
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  align-items: flex-start;
  gap: 10px;
}

@media (max-width: 600px) {
  .item-header-container {
    flex-direction: column;
  }

  .item-header-title-container {
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
  }

  .item-header-title {
    justify-self: flex-start;
  }
}

.item-header-title {
}

.item-header-date {
  font-size: calc(8pt + 0.8vw);
  margin-top: 10px;
  color: var(--text-secondary);
  margin-bottom: 0;
}

.item-header-location {
  font-size: calc(8pt + 0.6vw);
  color: var(--text-tertiary);
  margin-top: 0;
}

.item-section-name {
  font-size: calc(10pt + 0.8vw);
}
